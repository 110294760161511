// src/components/ProductPanel/ProductPanel.scss

.product-panel {
    .product-card {
        position: relative;
        height: 100%;
        cursor: pointer;
        transition: transform 0.2s, box-shadow 0.2s;
        overflow: hidden;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }

        .product-image {
            height: 240px;
            object-fit: cover;
            transition: transform 0.3s;

            &:hover {
                transform: scale(1.05);
            }
        }

        .overlay-text {
            position: absolute;
            bottom: 60px;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            color: white;
            padding: 8px 16px;

            .brand-name {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 0;
            }

            .product-name {
                font-size: 14px;
                margin-top: 0;
            }
        }

        .product-description {
            height: 60px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            background-color: #f5f5f5;
            position: absolute; /* Add this */
            bottom: 0; /* Add this */
            padding-top: 10px;
            width: 100%; /* Add this */
            z-index: 2; /* Add this */
        }
    }
}

// Dialog styles
.dialog-content {
    margin-top: 16px;

    .product-info-section {
        margin-bottom: 24px;
    }

    .variant-chip {
        margin-right: 8px;
        margin-bottom: 8px;
    }

    .product-image-container {
        display: flex;
        justify-content: center;
        margin: 16px 0;

        img {
            //max-height: 300px;
            object-fit: contain;
        }
    }

    .ingredients-section {
        margin-top: 24px;
        padding: 16px;
        background-color: #f9f9f9;
        border-radius: 4px;
    }
}