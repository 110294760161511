
.catalog-container {
  padding: 30px 0;
  background-color: #f5f5f5;
  min-height: 100vh;

  .product-grid-item {
    margin-bottom: 20px;
    height: 100%;
  }
}

// Media query for mobile devices
@media (max-width: 600px) {
  .catalog-container {
    padding: 15px 0;
  }
}

// src/components/Catalog/catalog.scss

.catalog-container {
  padding: 24px 0;

  .product-grid-item,
  .ordering-grid-item {
    display: flex;
    margin-bottom: 20px;

    & > div {
      width: 100%;
    }
  }

  // Ensure the grid items maintain consistent heights
  @media (min-width: 960px) {
    .product-grid-item,
    .ordering-grid-item {
      height: 350px;
    }
  }
}

.catalog-container {
  .MuiGrid-container {
    row-gap: 6px !important; /* Reduce the default row gap */
  }

  .product-grid-item,
  .ordering-grid-item {
    margin-bottom: 0; /* Remove any bottom margin on grid items */
  }
}