// src/components/OrderingPanel/OrderingPanel.scss

.ordering-panel {
  height: 100%;
  .ordering-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .ordering-header {
    background-color: rgb(5, 150, 105);
    .MuiTypography-root {
      color: white;
    }
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
  }

  .ordering-title {
    font-weight: 600;
    color: #333;
  }
}